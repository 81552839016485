<template>
  <div class="stepContent commonClass">
    <div class="flex relative">
      <h3 class="titHead">测量记录</h3>
    </div>
    <div class="detail">
      <div class="flex  backReturn">
        <h4 class="title">{{ measure.createTime | formatDay }} 测量记录复核</h4>
        <div>
          <el-button size="small" plain class="return">导出</el-button>
          <el-button size="small" plain class="return" @click="$emit('changePage', 1,null,2)">返回列表页</el-button>
        </div>
      </div>
      <table class="table specTable">
        <tr>
          <th colspan="2">方案编号</th>
          <td colspan="3">{{ activeInfo.number }}</td>
          <th colspan="2">动物品系</th>
          <td colspan="2">{{ activeInfo.strain }}</td>
        </tr>
        <tr>
          <th colspan="2">使用仪器</th>
          <td colspan="7">
            <span v-for="item in measure.instrument">[ 型号：{{ item.instruModel }}  名称：{{
                item.instrumentName
              }} ]  </span>
          </td>
        </tr>
        <tr>
          <th colspan="2">组别</th>
          <th>笼号</th>
          <th>动物编号</th>
          <th width="100">耳号</th>
          <th width="100">性别</th>
          <th>体重 (g)</th>
          <th>瘤径a (mm)</th>
          <th>瘤径b (mm)</th>
        </tr>
        <template v-for="item in groupMap">
          <tr v-for="(obj,index) in item">
            <td colspan="2" :rowspan="item.length" v-if="index==0">{{ obj.groupName }}</td>
            <td >{{ obj.cageNo }}</td>
            <td>{{ obj.aniNo }}</td>
            <td>{{ obj.overbit }}</td>
            <td>{{ obj.gender == 1 ? '♂' : '♀' }}</td>
            <td>{{ obj.weight }}</td>
            <td>{{ obj.tumorSizeA }}</td>
            <td>{{ obj.tumorSizeB }}</td>
          </tr>
        </template>
        <tr>
          <td colspan="2">操作人</td>
          <td colspan="3">{{ measure.createName }}</td>
          <td colspan="2">日期</td>
          <td colspan="2">{{ measure.createTime | formatDay }}</td>
        </tr>
        <tr>
          <td colspan="2">复核人</td>
          <td colspan="3">{{ measure.reviewName }}</td>
          <td colspan="2">日期</td>
          <td colspan="2">{{ measure.reviewDate | formatDay }}</td>
        </tr>
      </table>
      <template v-if="state==1&&checkState">
        <h4 class="title">复核结果</h4>
        <el-form :model="measureForm" ref="measureForm" size="small" class="addForm" label-suffix=":"
                 label-width="90px">
          <el-form-item label="复核结果">
            <el-radio-group v-model="measureForm.state" class="radioGroup">
              <el-radio :label="2">复核通过</el-radio>
              <el-radio :label="3">返回修改(复核意见必填)</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="复核意见" prop="reviewRemark"
                        :rules="validateType">
            <el-input type="textarea" rows="4" placeholder="请输入复核意见" v-model="measureForm.reviewRemark"></el-input>
          </el-form-item>
          <div class="fromSave">
            <el-button type="primary" size="small" @click="checkSubmit('measureForm')">复核完成</el-button>
          </div>
        </el-form>
      </template>
      <template v-else-if="state==0||state==1&&!checkState"></template>
      <template v-else>
        <h4 class="title">复核结果</h4>
        <div class="desc">
          <p>复核结果：{{ measure.state == 2 ? '复核通过' : measure.state == 3 ? '复核拒绝' : '' }}</p>
          <p>复核意见：{{ measure.reviewRemark }}</p>
          <span>复核人：{{ measure.reviewName }}</span>
          <span>复核日期：{{ measure.reviewDate | formatDay }}</span>
        </div>
      </template>
    </div>
  </div>

</template>

<script>
import {mapState} from "vuex";

export default {
  name: "measurementEnd",
  props: ['id', 'measureId'],
  data() {
    return {
      activeInfo: {},
      measure: {},
      state: null,
      checkState: false,
      groupMap: {},
      measureForm: {
        state: 2,
        reviewRemark: "",
      },
    }
  },
  mounted() {
    this.$get("/subject/" + this.id).then((res) => {
      if (res.status == 200) {
        this.activeInfo = res.data
      }
    })
    this.getMeasuDetail()
  },
  computed: {
    ...mapState({
      user: state => state.account.user
    }),
    validateType() {
      return this.measureForm.state === 3
          ? [{required: true, message: '请输入复核意见', trigger: 'blur'}]
          : []
    },
  },
  methods: {
    getMeasuDetail() {
      this.$get('/subject/measure/' + this.measureId).then(res => {
        if (res.status == 200) {
          this.state = res.data.measure.state
          if (this.user.userId == res.data.measure.reviewer) {
            this.checkState = true
          }
          res.data.measure.instrument = JSON.parse(res.data.measure.instrument)
          // 基本信息
          this.measure = res.data.measure
          // 表格
          this.groupMap = res.data.groupMap
        }
      })
    },
    // 提交复核
    checkSubmit(checkForm) {
      this.$refs[checkForm].validate((valid) => {
        if (valid) {
          this.measureForm.id = this.measureId
          this.$put('/subject/measure/state', this.measureForm).then(res => {
            if (res.status == 200) {
              this.$message.success('提交成功')
              this.$emit('changePage', 1, null, 2)
            }
          })
        } else {
          return false;
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";

.tabsCage {
  margin-left: 0;
}
</style>
